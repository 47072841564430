import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VXETable from 'vxe-table'
import Axios from 'axios'
import 'vxe-table/lib/style.css'
import VueRouter from 'vue-router'

import router from './router'
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
Vue.prototype.$axios = Axios;
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VXETable)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
