import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'
// import { MessageBox } from "element-ui"  // 弹框组件
Vue.use(VueRouter)

const defaultRouterMap = [
  {
    path: '/',
    name: 'automatedGeneration',
    component: resolve => require(['@/view/automated/automated'], resolve),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: "*",
    redirect: "@/view/automated/automated"
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: defaultRouterMap
})

// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem('token');
//   store.state.token = token;
//   if (to.matched.some(res => res.meta.requireAuth)) {// 判断是否需要登录权限
//     if (token == null || token == "") {
//       MessageBox.confirm('token失效或没有权限访问此页面，请重新登录。', '提示', {
//         confirmButtonText: '确定',
//         type: 'warning'
//       }).then(() => {
//         next('/');
//       })
//     } else if (token != null || token != "") {//token不为空或null的情况，执行当前页
//       next();
//     } else {
//       next({
//         path: '/',
//         query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
//       })
//     }
//   } else {
//     next()
//   }
// });

Vue.mixin({
  beforeRouteLeave: function (to, from, next) {
    if (this.$vnode && this.$vnode.data.keepAlive) {
      if (this.$vnode.parent && this.$vnode.parent.componentInstance && this.$vnode.parent.componentInstance.cache) {
        if (this.$vnode.componentOptions) {
          const cache = this.$vnode.parent.componentInstance.cache
          const keys = this.$vnode.parent.componentInstance.keys
          console.log(keys)
          const waitDelKeys = []
          keys.forEach((k) => {
            // 判断是否缓存了同类页面
            if (/\/user\/user-service\//.test(k)) {
              // 非自己页面，把key存入待销毁数组里
              if (k.indexOf(this.$route.fullPath) === -1) {
                waitDelKeys.push(k)
              }
            }
          })
          // 销毁缓存
          waitDelKeys.forEach((wk) => {
            const index = keys.indexOf(wk)
            keys.splice(index, 1)
            delete cache[wk]
          })
        }
      }
    }
    next()
  }
})

export default router
